import React, { Fragment } from "react"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"
import bannerImg from "../assets/images/family-culture-banner.jpg"
import familyCultureImg from "../assets/images/family-culture.svg"
import familyCultureBenefitsImg from "../assets/images/family-culture-benefits.svg"
import forBusinessImg from "../assets/images/for-business.svg"
import forUsersImg from "../assets/images/for-users.svg"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"

const FamilyCulture = () => {
  return (
    <LayoutAnimate>
      <section className="page-banner-section">
        <Parallax
          bgImage={bannerImg}
          bgImageAlt="Call to action"
          strength={200}
          className="cta-section"
          renderLayer={percentage => (
            <div
                style={{
                    position: 'absolute',
                    background: `linear-gradient(to right, rgba(2, 23, 32,0.8),rgba(2, 23, 32,0.2))`,
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                }}
            />
        )}
        >
          <div className="page-banner-wrapper">
            <Container
              className="page-banner-title-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-delay="200"
            >
              <h2>Family Culture</h2>
            </Container>
            <div className="breadcrumnb-wrapper">
              <Container
                className="breadcrumb-container pb-3"
                data-sal-duration="1000"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="400"
              >
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Family Culture</Breadcrumb.Item>
                </Breadcrumb>
              </Container>
            </div>
          </div>
        </Parallax>
      </section>
      <section className="family-culture-section layout-padding">
        <Container>
          <Row className="align-items-center flex-column-reverse flex-md-row">
            <Col md={6}>
              <div className="detail-box mt-5 mt-md-0"  data-sal-duration="1000"
                  data-sal="slide-right"
                  data-sal-easing="ease">
                <h4 className="text-secondary">What does family culture mean to you?</h4>
                <p>
                  Everyone spends the majority of their time at work. We try to
                  create a family culture feeling where we feel comfortable at
                  work, A healthy work environment.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="img-box"  data-sal-duration="1000"
                  data-sal="slide-left"
                  data-sal-easing="ease">
                <img
                  src={familyCultureImg}
                  alt="family culture image"
                  className="w-100 img-fluid"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center layout-padding2 mt-4 mt-md-0">
            <Col md={6}>
              <div className="img-box" data-sal-duration="1000"
                  data-sal="slide-right"
                  data-sal-easing="ease">
                <img
                  src={familyCultureBenefitsImg}
                  alt="family culture image"
                  className="w-100 img-fluid"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="detail-box" data-sal-duration="1000"
                  data-sal="slide-left"
                  data-sal-easing="ease">
                <h4 className="mt-4 text-secondary">
                  What are the advantages of a Family Business Culture?
                </h4>
                <p>
                  Being family owned but not quoted on the stock market, We can
                  afford to work for the long- term without having to worry
                  about the pressure of quarterly results. Moreover, All profits
                  are Re-invested back into the company and much of it into
                  education.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center flex-column-reverse flex-md-row mt-5 mt-md-0">
            <Col md={6}>
              <div className="detail-box mt-5 mt-md-0" data-sal-duration="1000"
                  data-sal="slide-right"
                  data-sal-easing="ease">
                <h2 className="mt-5">For Business Partners</h2>
                <h4 className="text-secondary">
                  What are the advantages of Family Culture for Business
                  Partners?
                </h4>
                <ul>
                  <li>Very Ethical Transparent policy</li>
                  <li>
                    Pressure free environment to grow business with
                    concentration
                  </li>
                  <li>
                    Well supportive staff which provide proper guidance for
                    growing business.
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="img-box" data-sal-duration="1000"
                  data-sal="slide-left"
                  data-sal-easing="ease">
                <img
                  src={forBusinessImg}
                  alt="business partners image"
                  className="w-100 img-fluid"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center layout-padding2-top mt-4 mt-md-0">
            <Col md={6}>
              <div className="img-box" data-sal-duration="1000"
                  data-sal="slide-right"
                  data-sal-easing="ease">
                <img
                  src={forUsersImg}
                  alt="Business user image"
                  className="w-100 img-fluid"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="detail-box" data-sal-duration="1000"
                  data-sal="slide-left"
                  data-sal-easing="ease">
                <h2 className="mt-5">For Users</h2>
                <h4 className="text-secondary">What are the advantages of Family Culture for Users?</h4>
                <p>
                  The products developed by young Technocrats who well versed
                  with latest Technology being used around the world in 21st
                  century. Thus provides best services and quality products with
                  guidelines of correct product application. Otherwise share
                  your application area, we are creating customize product for
                  that application.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </LayoutAnimate>
  )
}

export default FamilyCulture
